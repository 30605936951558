import React from "react";

function AllowanceLink({activeCurrency, allowanceProblem ,onApproveToken}) {
  let result = null
  if (allowanceProblem && activeCurrency === 'usdt')
    result = <div className="pkg-text" onClick={()=>onApproveToken('usdt')}>Activating this package requires spend allowance.
        <span className="s-link">(click here to allow)</span>
      </div>

/*   if (allowance.dexo < requireSpendAllowance.dex && activeCurrency === 'dexo')
    result = <div className="pkg-text" onClick={()=>onApproveToken('dexo')}>Activating this package requires spend allowance.
        <span className="s-link">(click here to allow)</span>
      </div> */

  if (allowanceProblem && activeCurrency === 'onesd')
    result = <div className="pkg-text" onClick={()=>onApproveToken('onesd')}>Activating this package requires spend allowance.
      <span className="s-link">(click here to allow)</span>
    </div>
  return result
}

function PackageButton ({stakePending, onPackageSelect, activeCurrency, userTokens, price, allowanceProblem, platformFeeProblem}) {
  let result = null
  if (platformFeeProblem) {
    result = <button className="sbtn sbtn-disabled">Platform Fee Required</button>
  } else if (stakePending) {
    result = <button className="sbtn sbtn-disabled">Processing...</button>
  } else if (userTokens[activeCurrency] < price) {
    result = <button className="sbtn sbtn-disabled">Insufficient Funds</button>
  } else if (allowanceProblem) {
    result = <button className="sbtn sbtn-disabled">Activate</button>
  } else {
    result = <button className="sbtn sbtn-primary" onClick={() => onPackageSelect()}>Activate</button>
  }
  return result
}

function Package(props) {
  const {title, price, onPackageSelect, stakePending, allowance, onApproveToken, description1, description2, activeCurrency, userTokens, platformFeeProblem} = props
  const amountShort = activeCurrency === "dexo" ? price - allowance.dexo : activeCurrency === "usdt" ? price - allowance.usdt : price - allowance.onesd;
  return (
    <div className="pkg">
      <div className="pkg-header">
        <div className="pkg-title">{title}</div>
        <div className="pkg-price">{price.toLocaleString()} {(activeCurrency === "onesd" ? "1SD" : activeCurrency).toUpperCase()}</div>
        <div className="pkg-note">{description1}</div>
        <div className="pkg-note">{description2}</div>
      </div>
      <AllowanceLink activeCurrency={activeCurrency} allowanceProblem={amountShort > 0} onApproveToken={(token)=>onApproveToken(token)} />
      <div className="pkg-button">
        <PackageButton
          price={price}
          activeCurrency={activeCurrency}
          userTokens={userTokens}
          allowanceProblem={amountShort > 0}
          platformFeeProblem={platformFeeProblem}
          stakePending={stakePending}
          onPackageSelect={onPackageSelect}
        />
      </div>
    </div>
  );
}

export default Package;