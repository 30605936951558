import axios from "axios"
import { BASE_URL } from "../constants";
import authHeader from "./authHeader"

class AdminService {
  refreshKey(data) {
    return axios.post(BASE_URL + '/user/refresh-key', data, { headers: authHeader() });
  }
  getSettings() {
    return axios.get(BASE_URL + '/admin/settings');
  }
  saveSettings(data) {
    return axios.post(BASE_URL + '/admin/settings', data, { headers: authHeader() });
  }
  getStats(wallet) {
    return axios.get(BASE_URL + '/admin/get-stats?wallet=' + wallet, { headers: authHeader() });
  }
  getContactFormSubmissions() {
    return axios.get(BASE_URL + '/admin/contacts', { headers: authHeader() });
  }
  searchContactFormSubmissions(query) {
    return axios.post(BASE_URL + '/admin/contacts', {query}, { headers: authHeader() });
  }
  getUsers() {
    return axios.get(BASE_URL + '/admin/users', { headers: authHeader() });
  }
  getUsersTree() {
    return axios.get(BASE_URL + '/admin/tree', { headers: authHeader() });
  }
  log(user, func, message) {
    return axios.post(BASE_URL + '/admin/log', {user, func, message});
  }
  saveContent(content, position) {
    return axios.post(BASE_URL + '/admin/post-content', {content, position}, { headers: authHeader() });
  }
  postAnnouncements(data) {
    return axios.post(BASE_URL + '/admin/post-anc', data, { headers: authHeader() });
  }
  delAnnouncements(id) {
    return axios.post(BASE_URL + '/admin/del-anc', {id}, { headers: authHeader() });
  }
}

export default new AdminService();