import React, {useEffect, useState} from "react";
import { contractAbi, contractAddress} from "../../constants";

function AdminContract(props) {
  const {moralisObj,} = props
  const {user, Moralis} = moralisObj
  const [afState, setAfState] = useState([])
  
  useEffect(() => {
    const mutatedAbi = contractAbi.filter(ca => ca.stateMutability === "nonpayable" && ca.type === "function").map(abi => {
      let params = {}
      abi.inputs.map(inpts => params[inpts.name] = '')
      return {
        fn: abi.name,
        params,
        isLoading: false,
      }
    })
    setAfState(mutatedAbi)
  }, [, user])

  function setInput(e) {
    let tempState = JSON.parse(JSON.stringify(afState))
    const fnName = e.target.name.split('_')[0]
    const inpName = e.target.name.split('_')[1]
    const inpValue = e.target.value
    tempState = tempState.map(ts=> {
      if (ts.fn === fnName) {
        ts.params[inpName] = inpValue
      }
      return ts
    })
    setAfState(tempState)
  }

  async function execFn(fnName) {
    let tempState = JSON.parse(JSON.stringify(afState))
    tempState = tempState.map(ts=> {
      if (ts.fn === fnName) {
        ts.isLoading = true
      }
      return ts
    })
    setAfState(tempState)

    const target = afState.filter(afs=> afs.fn === fnName)[0]
    const fn = {
      contractAddress,
      functionName: target.fn,
      abi: contractAbi,
      params: target.params
    };
    try {
      const res = await Moralis.executeFunction(fn)
      const result = await res.wait()
      if (result.status === 1) {}
    } catch {
    } finally {
      tempState = JSON.parse(JSON.stringify(afState)).map(ts=> {
        if (ts.fn === fnName) {
          ts.isLoading = false
        }
        return ts
      })
      setAfState(tempState)
    }
  }

  function getSafe(fn, defaultVal) {
    try {
      return fn();
    } catch (e) {
      return defaultVal;
    }
  }

  return <div className="row tight">
    {contractAbi.filter(ca => ca.stateMutability === "nonpayable" && ca.type === "function").map(ca => <div key={ca.name} className="col-xs-12 col-lg-6">
      <div className="admin-fn-box white-box">
        <div className={`busy-overlay ${getSafe(() => afState.filter(afs=>afs.fn === ca.name)[0].isLoading, false) ? 'active' : ''}`}>
          <div className="spinner"></div>
          <div className="spinner-text"></div>
        </div>
        <h4>{ca.name}</h4>
        <div className="afb-body">
          {ca.inputs.map((inp,indx) => <div key={ca.name + indx} className="afb-input">
            <label>{inp.name}</label>
            <input className="cs-textinput" name={ca.name+'_'+inp.name} placeholder={inp.type} onChange={(e)=>setInput(e)}></input>
          </div>)}
        </div>
        <div className="afb-footer">
          <button className="sbtn sbtn-primary" onClick={()=>execFn(ca.name)}>Execute</button>
        </div>
      </div>
    </div>)}
  </div>
}

export default AdminContract;