
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Web3 from "web3";
import {ethers} from 'ethers';
import Slider from 'rc-slider';
import BigNumber from "bignumber.js";
import moment from 'moment';
import StakingChart from "../components/StakingChart";
import '../components/rc-slider.css';
import { chainName, dexoAbi, dexoContract, masterWallet } from "../constants";
import StakingService from "../services/staking.service";
import AdminService from "../services/admin.service";
import 'intro.js/introjs.css';

function Staking({ moralisObj, setCurrentJob, elevated, onFlash}) {
  const {
    isWeb3Enabled,
    isAuthenticated,
    user,
    Moralis,
  } = moralisObj

  const round18 = inp => Math.floor(inp/1000000000000000000)

  const [showAnimation, setShowAnimation] = useState(true);
  const [checking, setChecking] = useState(false);
  const [userStakes, setUserStakes] = useState([]);

  const [stakingInProgress, setStakingInProgress] = useState(false);
  const [unStakingInProgress, setUnStakingInProgress] = useState(false);
  
  const navigate = useNavigate();

  const [userDexo, setUserDexo] = useState(0)
  const [stakingDexo, setStakingDexo] = useState(0)
  const [dexoUsdRate, setDexoUsdRate] = useState(0);
  const [days, setDays] = useState(90);
  const [currentDexoPrice, setCurrentDexoPrice] = useState(0);
  const [futureDexoPrice, setFutureDexoPrice] = useState(0);
  const [apy, setApy] = useState(0);
  const [customApy, setCustomApy] = useState(0);
  const [dexoRoi, setDexoRoi] = useState(0);
  const [usdRoi, setUsdRoi] = useState(0);
  const [finalDexo, setFinalDexo] = useState(0);
  const [chartData, setChartData] = useState();
  const [chartData2, setChartData2] = useState();
  const [inputFactor, setInputFactor] = useState(1);
  const [stakingAmount, setStakingAmount] = useState(0);
  const [futureDexoValueInDollar, setFutureDexoValueInDollar] = useState(0);
  const [maxReached, setMaxReached] = useState(false);

  useEffect(() => {
    if (isAuthenticated && isWeb3Enabled) {
      setCurrentJob('Loading data...')
      if (!checking) checkInitialValues()
      setChecking(true)
    }
    if(!user) navigate(`/${window.location.search}`, { replace: true });
  }, [isWeb3Enabled, isAuthenticated, navigate, user]);

  async function checkInitialValues() {
    try {
      const [_tokens, _stakes] = await Promise.all([Moralis.Web3API.account.getTokenBalances({ chain: chainName}), StakingService.userStakes(user.attributes.ethAddress), getDexoRate()]);
      setUserStakes(_stakes.data)
      _tokens.map(tkn => {
        if (tkn.token_address === dexoContract.toLowerCase()) {
          const tkb = tkn.balance
          setUserDexo(tkb)
          setStakingAmount(round18(tkb) > 1000 ? round18(tkb) - 1000: 0)
          setStakingDexo(round18(tkb))
        }
        return null
      })
    } catch (err) {
      console.log(err)
    } finally {
      setCurrentJob('')
      setChecking(false)
    }
  }

  function prettyDisplayNumber(number) {
    if (number.gte(1000)) {
      return number.toFormat(0);
    } else if (number.gt(1)) {
      return number.toFormat(2);
    } else {
      return trimVerboseDecimals(number.toFormat());
    }
  }

  /* function convertFarsiDigitsToEnglish(inp) {
    let result = "";
    for (let char of inp.toString()) {
        if (char === undefined) break;
        let charCode = char.charCodeAt(0);
        if (charCode < 1786 && charCode > 1775) {
            result += String.fromCharCode(charCode - 1728);
        } else {
            const tmpChar = Number(char)
            result += tmpChar || '';
        }
    }
    return result;
  } */
  
  function trimVerboseDecimals(number) {
    if (number.indexOf(".") === -1) return number;
    return Number(number).toFixed(3)
  }

  useEffect(() => {
    const DAYS_PER_YEAR = 730;
    const inputApy = parseFloat(customApy) / 100;
    const apr = DAYS_PER_YEAR * Math.pow(inputApy + 1, 1 / DAYS_PER_YEAR) - DAYS_PER_YEAR;
    const startBalance = parseFloat(stakingDexo);
    const normalizeFactor = (1 - (days/DAYS_PER_YEAR))*6 + 1
    const endBalance = startBalance * Math.pow(1 + (apr / DAYS_PER_YEAR)/normalizeFactor, days)
    setFinalDexo(prettyDisplayNumber(new BigNumber(endBalance)));
    const newPotentialReturn = endBalance * (parseFloat(futureDexoPrice) || 0);

    const tempDexoRoi = prettyDisplayNumber(new BigNumber(((endBalance - startBalance) / startBalance) * 100))
    setDexoRoi(tempDexoRoi === 'NaN' ? 0 : tempDexoRoi);
    
    setFutureDexoValueInDollar(prettyDisplayNumber(new BigNumber(endBalance*futureDexoPrice)))

    const cdp = stakingDexo * currentDexoPrice
    const tempUsdRoi = prettyDisplayNumber(new BigNumber(((newPotentialReturn - cdp) / cdp) * 100))
    setUsdRoi(tempUsdRoi === 'NaN' ? 0 : tempUsdRoi);
    generate()
  }, [days, customApy, futureDexoPrice, stakingDexo, currentDexoPrice, inputFactor]);


  const getDexoRate = async () => {
    let conversion_rate = 0
    let tempApy = 0
    setCurrentJob('Loading data...')
    const settings = await AdminService.getSettings()
    settings.data.map(st=>{
      if (st.settingId === 'conversion_rate') conversion_rate = st.settingValue
      if (st.settingId === 'apy') tempApy = st.settingValue
    })
    setCurrentJob('')
    setApy(tempApy)
    setCustomApy(tempApy)
    setDexoUsdRate(conversion_rate)
    setCurrentDexoPrice(conversion_rate);
    setFutureDexoPrice(conversion_rate);
  }

  const onChange = (e) => {
    const inpName = e.target.name
    const inpValue = e.target.value
    if (inpName === 'apy') setCustomApy(inpValue)
    if (inpName === 'staking_dexo') setStakingDexo(inpValue)
    if (inpName === 'current_dexo_price') setCurrentDexoPrice(inpValue)
    if (inpName === 'future_dexo_price') setFutureDexoPrice(inpValue)
    if (inpName === 'staking_amount') {
      const numInpValue = Number(inpValue)
      setMaxReached(numInpValue > round18(userDexo))
      let regulatedStakingAmount = numInpValue < 1 ? 0 : numInpValue > round18(userDexo) ? round18(userDexo) : numInpValue
      regulatedStakingAmount = round18(userDexo) - regulatedStakingAmount < 1000 && round18(userDexo) > 1000 ? round18(userDexo) - 1000 : regulatedStakingAmount
      setStakingAmount(regulatedStakingAmount)
    }
  }

  Number.prototype.noExponents = function() {
    var data = String(this).split(/[eE]/);
    if (data.length == 1) return data[0];
  
    var z = '',
      sign = this < 0 ? '-' : '',
      str = data[0].replace('.', ''),
      mag = Number(data[1]) + 1;
  
    if (mag < 0) {
      z = sign + '0.';
      while (mag++) z += '0';
      return z + str.replace(/^\-/, '');
    }
    mag -= str.length;
    while (mag--) z += '0';
    return str + z;
  }

  const stake = async () => {
    if (stakingInProgress) return onFlash({message: 'Another request is in progress. please wait', type:'warning'})
    setStakingInProgress(true)
    for (let ustk of userStakes) {
      const days = moment().diff(moment(ustk._created_at), 'days')
      if (days < 1) {
        onFlash({message: 'You can only stake once a day. Try again tomorrow', type:'danger'})
        return
      }
    }
    if (stakingAmount < 2000) {
      onFlash({message: 'Minimum amount for staking is 2000 DEXO', type:'danger'})
      return
    }
    const stakingAmount18 = (stakingAmount * 1000000000000000000).noExponents()
    //AdminService.log(user.attributes.ethAddress, 'stake started', stakingAmount18)
    const options = {
      type: "erc20",
      amount: stakingAmount18, 
      receiver: masterWallet,
      contractAddress: dexoContract
    }
    let preActId
    try {
      /* const preAct = await StakingService.prestake(user.attributes.sessionToken, stakingAmount18)
      if (preAct.data === 'error') {
        onFlash({message: 'There was an error. please contact admin', type:'danger'})
        throw 'Invalid data'
      }
      preActId = preAct.data*/
      let result
      //if (window.ethereum /* && navigator.userAgent.indexOf("MetaMaskMobile") === -1 */) {
        /* setCurrentJob('Checking prerequisites...')
        const web3 = new Web3(window.ethereum);
        const dexoContractObject = new web3.eth.Contract(dexoAbi, dexoContract)
        const allowance = await dexoContractObject.methods.allowance(user.attributes.ethAddress, user.attributes.ethAddress).call()
        if (Number(allowance) < Number(stakingAmount18)) {
          setCurrentJob('Getting approval...')
          const approveTx = await dexoContractObject.methods.approve(user.attributes.ethAddress, stakingAmount18).send({from: user.attributes.ethAddress})
          if (!approveTx.status) throw({message: "Approval failed."})
        }
        setCurrentJob('Waiting for transfer confirmation...')
        result = await dexoContractObject.methods.transferFrom(user.attributes.ethAddress, masterWallet, stakingAmount18).send({from: user.attributes.ethAddress});
      } else { */
        setCurrentJob('Waiting for transfer confirmation...')
        result = await Moralis.transfer(options)
      //}
      setCurrentJob('Waiting for transaction...')
      const stakeResult = await StakingService.stake(result.hash || result.transactionHash, user.attributes.ethAddress, preActId)
      if (stakeResult.data.result === 'done') {
        setCurrentJob('Loading data...')
        if (!checking) checkInitialValues()
        setChecking(true)
      } else {
        setCurrentJob('')
      }
    } catch (err) {
      console.log(err)
      onFlash({message: err.message || 'Something went wrong (error 61)', type:'danger'})
      if (err.code !== 4001){
        AdminService.log(user.attributes.ethAddress, 'stake', err)
      }
      await StakingService.removePrestake(user.attributes.sessionToken, preActId)
    } finally {
      setCurrentJob('')
      setStakingInProgress(false)
    }
  }

  const unstake = async (id) => {
    if (unStakingInProgress) return onFlash({message: 'Another request is in progress. please wait', type:'warning'})
    setUnStakingInProgress(true)
    setCurrentJob('Unstaking...')
    try {
      const stakeResult = await StakingService.unstake(id)
      if (stakeResult.data.result === 'done') {
        setCurrentJob('Loading data...')
        if (!checking) checkInitialValues()
        setChecking(true)
      }
    } catch (err) {
      console.log(err)
    } finally {
      setCurrentJob('')
      setUnStakingInProgress(false)
    }
  }

  const generate = async ()=>{
    let yieldsOverTime = {values:[],categories:[]}
    let yieldsOverTimeNew = []
    const DAYS_PER_YEAR = 730;
    const inputApy = parseFloat(customApy) / 100;
    const apr = DAYS_PER_YEAR * Math.pow(inputApy + 1, 1 / DAYS_PER_YEAR) - DAYS_PER_YEAR;
    const startBalance = parseFloat(stakingDexo);
    for (let i=0; i<=730; i++) {
      const normalizeFactor = (1 - (i/730))*Number(inputFactor) + 1
      const defaultNormalizeFactor = (1 - (i/730))*6 + 1
      yieldsOverTime.categories.push(i)
      yieldsOverTime.values.push(startBalance * Math.pow(1 + (apr / DAYS_PER_YEAR)/defaultNormalizeFactor, i))
      yieldsOverTimeNew.push(startBalance * Math.pow(1 + (apr / DAYS_PER_YEAR)/normalizeFactor, i))
    }
    setChartData(yieldsOverTime)
    setChartData2(yieldsOverTimeNew)
  }

  function dayDiff(date) {
    const dayDiff = moment().diff(moment(date), 'days')
    let result = dayDiff + (dayDiff < 2 ? ' day' : ' days') + ' ago'
    result = dayDiff === 0 ? 'Today' : result
    return result
  }

  return (
    <div className="container">
      <div className="alert" style={{textAlign: 'left'}}>
          <div>-To stake, we strongly recommend using Google Chrome for desktop and Metamask extension to perform transactions. </div>
          <div>-If not available, you can use Metamask mobile app built-in browser. Open the Metamask app and then click browser on the side menu!</div>
          <div>-Mobile browsers such as Safari, Chrome, Opera, and Samsung browsers are NOT recommended. Especially on smartphones.</div>
      </div>
      <div className="white-box mb-2">
        <div className="tokens-box">
          <div className="tkb" id="tour_stk_stats1">
            <h5>Current DEXO Price</h5>
            <b>${dexoUsdRate}</b>
          </div>
          <div className="tkb" id="tour_stk_stats2">
            <h5>Current BPY<span style={{display:'block',fontWeight: 400, fontSize: '15px'}}>(Biannual Percentage Yield)</span></h5>
            <b>{apy}%</b>
          </div>
          <div className="tkb" id="tour_stk_stats3">
            <h5>Your DEXO Balance</h5>
            <b>{round18(userDexo).toLocaleString()} DEXO</b>
          </div>
        </div>
        {/* <div className="dexo-box">
          <div className="afb-input">
            <label>Stake Amount (DEXO)</label>
            <input name="staking_amount" className="cs-textinput" value={stakingAmount} onChange={(e)=>onChange(e)} inputMode="numeric"></input>
            {maxReached ? <div className="warning">Maximum available amount reached</div> : null}
          </div>
          <div className="sbtn sbtn-primary" onClick={stake}>Stake</div>
        </div> */}
      </div>
      <div className="white-box mb-2">
        <h3>Active Stakes</h3>
        {userStakes.filter(stk=>stk.status === "staked").length > 0 ? <table className="staking-table">
          <thead>
            <tr>
              <th>Start</th>
              <th>Initial Balance</th>
              <th>Dexo Price</th>
              <th>APY</th>
              <th>Current Balance</th>
              <th>Unstake</th>
            </tr>
          </thead>
          <tbody>
            {userStakes.filter(stk=>stk.status === "staked").map(stk=><tr key={stk._id} className="stake-row">
              <td><label>Start: </label>{moment(stk._created_at).format('MMM D YYYY')} ({dayDiff(stk._created_at)})</td>
              <td><label>Initial Balance: </label>{round18(stk.amount).toLocaleString()} DEXO</td>
              <td><label>Dexo Price: </label>${stk.dexoPrice}</td>
              <td><label>APY: </label>{stk.apy}%</td>
              <td><label>Current Balance: </label>{round18(stk.current_balance).toLocaleString()} DEXO</td>
              <td><button className="sbtn sbtn-secondary" onClick={()=>unstake(stk._id)}>Unstake</button></td>
            </tr>)}
          </tbody>
        </table> : <div style={{textAlign: 'center'}}>No data</div> }

      </div>
      <div className="white-box mb-2">
        <h3>Past Stakes</h3>
        {userStakes.filter(stk=>stk.status === "unstaked").length > 0 ? <table className="staking-table">
          <thead>
            <tr>
              <th>Start</th>
              <th>End</th>
              <th>Initial Balance</th>
              <th>Dexo Price</th>
              <th>APY</th>
              <th>Returned Amount</th>
            </tr>
          </thead>
          <tbody>
            {userStakes.filter(stk=>stk.status === "unstaked").map(stk=><tr key={stk._id} className="stake-row">
              <td><label>Start: </label>{moment(stk.createdAt).format('MMM D YYYY')}</td>
              <td><label>End: </label>{moment(stk.unstake_date).format('MMM D YYYY')}</td>
              <td><label>Initial Balance: </label>{round18(stk.amount).toLocaleString()} DEXO</td>
              <td><label>Dexo Price: </label>${stk.dexoPrice}</td>
              <td><label>APY: </label>{stk.apy}%</td>
              <td><label>Returned Amount: </label>{round18(stk.return_amount).toLocaleString()}</td>
            </tr>)}
          </tbody>
        </table> : <div style={{textAlign: 'center'}}>No data</div> }
      </div>
      
      <div className="white-box mb-2 stp_calculator" >
        <h3>Staking Calculator</h3>
        <div className="rcslider-container mb-3" onMouseEnter={()=>{setShowAnimation(false)}} onMouseLeave={()=>{setShowAnimation(true)}}>
          <label>Current: {`${days} ${days < 2 ? 'day' : 'days'}`}</label>
          <div className={`arrow-wrapper bounce ${!showAnimation ? 'aw-hide' : ''}`} style={{left: 'calc(' + days*100/730 + '% - 50px)'}}>
            <i className="fa fa-chevrons-right fa-2x"></i>
          </div>
          <span className="days-range dr-start">0 days</span>
          <span className="days-range dr-end">730 days (2 years)</span>
          <Slider min={0} max={730} defaultValue={90} value={days} onChange={(val) => setDays(val)} />
        </div>
        <div className="row mb-3">
          <div className="col-12 col-md-4">
            <div className="afb-input">
              <label>DEXO Amount</label>
              <input name='staking_dexo' className="cs-textinput" value={stakingDexo} onChange={(e)=>onChange(e)}></input>
            </div>
          </div>
          {elevated ? <div className="col-12 col-md-4">
            <div className="afb-input">
              <label>APY (%)</label>
              <input name='apy' className="cs-textinput" value={customApy} onChange={(e)=>onChange(e)}></input>
            </div>
          </div> : null }
          <div className="col-12 col-md-4">
            <div className="afb-input">
              <label>DEXO price at purchase ($)</label>
              <input name='current_dexo_price' className="cs-textinput" value={currentDexoPrice} onChange={(e)=>onChange(e)}></input>
            </div>
          </div>
          <div className="ccol-12 col-md-4">
            <div className="afb-input">
              <label>Future DEXO price ($)</label>
              <input name='future_dexo_price' className="cs-textinput" value={futureDexoPrice} onChange={(e)=>onChange(e)}></input>
            </div>
          </div>
          {elevated ? <div className="col-12 col-md-4">
            <div className="afb-input">
              <label>Growth Factor</label>
              <input className="cs-textinput" value={inputFactor} onChange={(e)=>setInputFactor(e.target.value)}></input>
            </div>
          </div> : null}
        </div>
        <hr className="mb-4 mt-4" />
        <table className="stp_return_amount">
          <tbody>
            <tr>
              <td>Initial balance:</td>
              <td className="text-right">
                <span className="stk-res-wrapper">
                  <span className="stk-res-l">{stakingDexo.toLocaleString()} DEXO</span>
                </span>
              </td>
            </tr>
            <tr>
              <td>Final balance after {`${days} ${days < 2 ? 'day' : 'days'}`}:</td>
              <td className="text-right">
                <span className="stk-res-wrapper">
                  <span className="stk-res-l">{finalDexo} DEXO</span>
                </span>
              </td>
            </tr>
            <tr>
              <td>Final balance value after {`${days} ${days < 2 ? 'day' : 'days'}`}:</td>
              <td className="text-right">
                <span className="stk-res-wrapper">
                  <span className="stk-res-l">${futureDexoValueInDollar}</span>
                </span>
              </td>
            </tr>
            <tr>
              <td>ROI (DEXO) after {`${days} ${days < 2 ? 'day' : 'days'}`}:</td>
              <td className="text-right">
                <span className="stk-res-wrapper">
                  <span className="stk-res-l">{dexoRoi}%</span>
                </span>
              </td>
            </tr>
            <tr>
              <td>ROI (USD) after {`${days} ${days < 2 ? 'day' : 'days'}`}:</td>
              <td className="text-right">
                <span className="stk-res-wrapper">
                  <span className="stk-res-r">{usdRoi}%</span>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {chartData && elevated ? <StakingChart chartData={chartData} chartData2={chartData2} day={days} baseDexo={stakingDexo}/> : null }
    </div>
  );
}

export default Staking;